"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMessageFromCode = exports.serializeError = exports.EthereumProviderError = exports.EthereumRpcError = exports.ethErrors = exports.errorCodes = void 0;
const classes_1 = require("./classes");
Object.defineProperty(exports, "EthereumRpcError", {
  enumerable: true,
  get: function () {
    return classes_1.EthereumRpcError;
  }
});
Object.defineProperty(exports, "EthereumProviderError", {
  enumerable: true,
  get: function () {
    return classes_1.EthereumProviderError;
  }
});
const utils_1 = require("./utils");
Object.defineProperty(exports, "serializeError", {
  enumerable: true,
  get: function () {
    return utils_1.serializeError;
  }
});
Object.defineProperty(exports, "getMessageFromCode", {
  enumerable: true,
  get: function () {
    return utils_1.getMessageFromCode;
  }
});
const errors_1 = require("./errors");
Object.defineProperty(exports, "ethErrors", {
  enumerable: true,
  get: function () {
    return errors_1.ethErrors;
  }
});
const error_constants_1 = require("./error-constants");
Object.defineProperty(exports, "errorCodes", {
  enumerable: true,
  get: function () {
    return error_constants_1.errorCodes;
  }
});
